<template>
  <div class="claims m-2 p-3 pb-5">
    <h1>Your claim history</h1>
    <b-table stacked="md" striped hover borderless :items="claims" :fields="fields" class="mb-5">
    </b-table>
  </div>
</template>

<script>

  import ApiProperties from '../../../src/main/resources/application'

  export default {
    name: 'claims',
    data: function() {
      return {
        claims: [],
        fields: [
          "status",
          { key: "planTypeName", label: 'Type'},
          { key: "id", label: 'Claim #' },
          { key: "disabilityDate", label: 'Benefit Begin Date'},
          { key: "expectedBenefitEndDate", label: 'Benefit End Date'},
        ],
        errors: []
      }
    },
    created() {
      this.$http.get(ApiProperties.api.claimsByPortalUser).then(response => {
        this.claims = response.data;
      }).catch(e => {
        this.errors.push(e);
      })
    }
  }
</script>